import React from 'react';
import "./ProjectCard.css";
const ProjectCard = ({ title, description, imageUrl, technologies, link, caseStudyLink }) => {
    return (
        // <div id='card' className="flex-col gap-16 rounded overflow-hidden">
        //     <section className='max-w-200 my-8 inline-block'>
        //         <img className="size-fit" src={imageUrl} alt={title} />
        //     </section>
        //     <section className="project-content inline-block flex-row gap-1">
        //         <div className="px-6 py-4">
        //             <div className="title font-bold text-xl mb-2">{title}</div>
        //             <p className="text-base">{description}</p>
        //             <div className="py-4">
        //                 {technologies.map((tech, index) => (
        //                     <span key={index} className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">{tech}</span>
        //                 ))}
        //             </div>
        //         </div>
        //         <div className="px-6 pt-4 pb-2">
        //             {link && <a href={link} className="btn mb-3 mr-3 min-h-50 max-h-6">View Project</a>}
        //             {caseStudyLink && <a href={caseStudyLink} className="btn max-h-3">Read More</a>}
        //         </div>
        //     </section>
        // </div>
        <div className="flex flex-col md:flex-row items-center gap-8 my-8 rounded overflow-hidden shadow-lg">
        <div className="md:w-1/2">
            <img className="w-full h-auto object-cover" src={imageUrl} alt={title} style={{aspectRatio: '16 / 9'}} />
        </div>
        <div className="md:w-1/2 px-6 py-4 flex flex-col justify-center gap-4">
            <h2 className="title font-bold text-xl mb-2">{title}</h2>
            <p className="text-base">{description}</p>
            <div className="flex flex-wrap gap-2">
                {technologies.map((tech, index) => (
                    <span key={index} className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700">{tech}</span>
                ))}
            </div>
            <div className="flex flex-row gap-3">
                {link && <a href={link} className="btn bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out">View Project</a>}
                {/* {caseStudyLink && <a href={caseStudyLink} className="btn bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out">Read More</a>} */}
            </div>
        </div>
    </div>
    
    );
};

export default ProjectCard;
