import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header'; // Adjust the path as necessary
// Import other components
import About from './components/About';
import Portfolio from './components/Portfolio';
import './App.css';
import HomePage from './components/HomePage';
import GameDesigner from './components/GameDesigner';
import UXDesigner from './components/UXDesigner';
import AIAppDeveloper from './components/AIAppDeveloper';
import LearningDesigner from './components/LearningDesigner';
import AdminPage from './components/AdminPage';


function App() {
  return (
    <Router>
      <div className="App">
      <Header />
        <Routes>
          <Route path="/" element={<HomePage/>}/>
          <Route path="/about" element={<About/>}/>
          <Route path="/portfolio"  element={<Portfolio/>}/>
          <Route path="/game-designer" element={<GameDesigner/>}/>
          <Route path="/ux-designer" element={<UXDesigner/>}/>
          <Route path="/ai-app-developer" element={<AIAppDeveloper/>}/>
          <Route path="/learning-designer" element={<LearningDesigner/>}/>
          <Route path="/admin" element={<AdminPage/>}/>
        </Routes>
      </div>
    </Router>
  );
}

export default App;
