// Header.jsx
import React from 'react';
// import { Link } from 'react-router-dom'; 

const Portfolio = () => {
    return (
        <div className="bg-gray-800 text-white p-4">
        <h1>Portfolio</h1>
        </div>
    );
};

export default Portfolio;
