import React from 'react';
import ProjectCard from './ProjectCard';

const LXDProjects = [
    {
        title: "UX Project 1",
        description: "An innovative solution to improve e-commerce shopping experience.",
        imageUrl: "path/to/image.jpg",
        technologies: ["Figma", "Adobe XD"],
        link: "http://example.com",
        caseStudyLink: "http://example.com/case-study"
    },
];

const LearningDesigner = () => {
    return (
        <div className="container mx-auto px-4 py-8 grid grid-cols-1 md:grid-cols-3 gap-4">
            {LXDProjects.map((project, index) => (
                <ProjectCard key={index} {...project} />
            ))}
        </div>
    );
};

export default LearningDesigner;
