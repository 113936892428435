import React, { useState, useEffect } from 'react';
import { FiEdit2, FiTrash2 } from 'react-icons/fi'; 

// Features to be built later: Add confirmation messages for insert, update, delete.
// Adding an admin login
// Implementing multi-select filter for categories
// Adding a search bar to filter projects by title
// Adding a pagination feature to display a limited number of projects per page

const AdminPage = () => {
    const [isEditing, setIsEditing] = useState(false);
    const [currentEditingId, setCurrentEditingId] = useState(null);
    const [projects, setProjects] = useState([]);
    const [projectForm, setProjectForm] = useState({
        id: null,
        title: '',
        description: '',
        imageUrl: '',
        technologies: '',
        link: '',
        caseStudyLink: '',
    });

    const BACKEND_URL = "https://open-ai-api-steel.vercel.app"; // Update this to your backend URL

    useEffect(() => {
        fetch(`${BACKEND_URL}/api/projects`)
            .then(response => response.json())
            .then(data => setProjects(data))
            .catch(error => console.error("Error fetching projects:", error));
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setProjectForm({ ...projectForm, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const url = `${BACKEND_URL}/api/projects/${isEditing ? currentEditingId : ''}`;
        const method = isEditing ? 'PUT' : 'POST';
      
        fetch(url, {
          method: method,
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(projectForm),
        })
        .then(response => response.json())
        .then(data => {
          console.log('Success:', data);
          if(isEditing) {
            setProjects(projects.map(project => project._id === data._id ? data : project));
            setIsEditing(false);
          } else {
            setProjects([...projects, data]);
          }
          setProjectForm({ 
            id: null,
            title: '',
            description: '',
            imageUrl: '',
            technologies: '',
            link: '',
            caseStudyLink: '',
          });
        })
        .catch((error) => {
          console.error('Error:', error);
        });
      };
      

    const handleEditClick = (project) => {
        setProjectForm({
          id: project._id,
          title: project.title,
          description: project.description,
          imageUrl: project.imageUrl,
          technologies: project.technologies.join(', '), 
          link: project.link,
          caseStudyLink: project.caseStudyLink,
        });
        setIsEditing(true);
        setCurrentEditingId(project._id);
      };
      

    const handleDelete = (id) => {
        fetch(`${BACKEND_URL}/api/projects/${id}`, {
            method: 'DELETE',
        })
        .then(() => {
            setProjects(projects.filter(project => project._id !== id));
        })
        .catch(error => console.error("Error deleting project:", error));
    };

    const [filter, setFilter] = useState('');

    const handleFilterChange = (e) => {
    setFilter(e.target.value);
    };

    const filteredProjects = projects.filter(project => {
    return filter ? project.category === filter : true;
    });


    return (
        <div className="container mx-auto px-4 py-8">
            <h1 className="text-2xl font-bold mb-4">Admin Dashboard</h1>
            <div> 
                <form onSubmit={handleSubmit} className="mb-8">
                    <label htmlFor="category" className="block text-gray-700 text-sm font-bold mb-2">Category:</label>
                    <select id="category" name="category" value={projectForm.category} onChange={handleChange} className="shadow border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline">
                        <option value="">Select a category</option>
                        <option value="Learning Design">Learning Design</option>
                        <option value="UX Design">UX Design</option>
                        <option value="Game Design">Game Design</option>
                        <option value="Web Development">Web Development</option>
                        <option value="AI Development">AI Development</option>
                    </select>
                    <div className="mb-4">
                        <label htmlFor="title" className="block text-gray-700 text-sm font-bold mb-2">Title:</label>
                        <input type="text" id="title" name="title" value={projectForm.title} onChange={handleChange} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="description" className="block text-gray-700 text-sm font-bold mb-2">Description:</label>
                        <input type="text" id="description" name="description" value={projectForm.description} onChange={handleChange} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="imageUrl" className="block text-gray-700 text-sm font-bold mb-2">Image URL:</label>
                        <input type="text" id="imageUrl" name="imageUrl" value={projectForm.imageUrl} onChange={handleChange} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="technologies" className="block text-gray-700 text-sm font-bold mb-2">Technologies:</label>
                        <input type="text" id="technologies" name="technologies" value={projectForm.technologies} onChange={handleChange} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="link" className="block text-gray-700 text-sm font-bold mb-2">Link:</label>
                        <input type="text" id="link" name="link" value={projectForm.link} onChange={handleChange} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="caseStudyLink" className="block text-gray-700 text-sm font-bold mb-2">Case Study Link:</label>
                        <input type="text" id="caseStudyLink" name="caseStudyLink" value={projectForm.caseStudyLink} onChange={handleChange} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
                    </div>
                    <button type="submit" className="btn">Submit</button>
                </form>
                <div className="mt-8">
                <h2 className="text-xl font-bold mb-4">Projects List</h2>
                <label htmlFor="filter" className="block text-gray-700 text-sm font-bold mb-2">Filter by Category:</label>
                <select id="filter" name="filter" value={filter} onChange={handleFilterChange} className="shadow border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline">
                    <option value="">All Categories</option>
                    <option value="Learning Design">Learning Design</option>
                    <option value="UX Design">UX Design</option>
                    <option value="Game Design">Game Design</option>
                    <option value="Web Development">Web Development</option>
                    <option value="AI Development">AI Development</option>
                </select>
                {filteredProjects.map((project, index) => (
                    <div key={index} className="mb-4 p-4 shadow rounded">
                        <h3 className="text-lg font-bold">{project.title}</h3>
                        <p>{project.description}</p>
                        <button onClick={() => handleEditClick(project)} className="mr-2">
                            <FiEdit2 /> {/* Edit icon */}
                        </button>
                        <button onClick={() => {handleDelete(project._id)}}>
                            <FiTrash2 /> {/* Delete icon */}
                        </button>
                    </div>
                ))}
                </div>
            </div> 
        </div>
    );
}
export default AdminPage;
