import React, {useEffect} from 'react';
import { Link } from 'react-router-dom'; 
import { FaLinkedin, FaGithub } from 'react-icons/fa'; 
import './ContactModal.css';


const ContactModal = ({ isOpen, onClose }) => {
    useEffect(() => {
        const handleOutsideClick = (event) => {
          if (event.target.id === "modalBackdrop") {
            onClose();
          }
        };
    
        window.addEventListener('click', handleOutsideClick);
    
        return () => window.removeEventListener('click', handleOutsideClick);
      }, [onClose]);

    if (!isOpen) return null;


  return (
    <div id="modalBackdrop" className="modalBackdrop fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm flex justify-center items-center p-4">
      <div className="modalContent bg-white p-6 rounded-lg shadow-lg max-w-sm">
        <h2 className="text-xl font-bold mb-4">Contact Me</h2>
        <p><strong>Email:</strong> <Link to="mailto:srivatsan.official@gmail.com">srivatsan.official@gmail.com</Link></p>
        <p><strong>Phone:</strong> <Link to="tel:+437-383-5250">+4373835250</Link></p>
        <p><strong>City:</strong> Toronto </p>
        {/* <a href="../assets/Srivatsan_Resume_2024.pdf" download="Resume" className="font-bold text-gray-900 hover:underline">Download Resume</a> */}
        <div className="flex justify-around mt-4">
          <a href="https://www.linkedin.com/in/srivatsan-rangarajan/" target="_blank" rel="noopener noreferrer"><FaLinkedin className="text-gray-900" size="24"/></a>
          <a href="https://github.com/Sriva29/" target="_blank" rel="noopener noreferrer"><FaGithub className="text-gray-900" size="24"/></a>
          {/* <a href="https://twitter.com" target="_blank" rel="noopener noreferrer"><FaTwitter className="text-blue-400" size="24"/></a> */}
        </div>
        {/* <button onClick={onClose} className="mt-4 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">Close</button> */}
      </div>
    </div>
  );
};

export default ContactModal;
