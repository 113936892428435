import React, {useEffect, useState} from 'react';
// import { Link } from 'react-router-dom';
import myHeroImage from '../assets/Srivatsan-Rangarajan.jpg'; 
import { ChatBubbleLeftIcon } from '@heroicons/react/24/solid';
import ProjectCard from './ProjectCard';


const HomePage = () => {
    const [projects, setProjects] = useState([]);
    const [filter, setFilter] = useState('');
    
    const BACKEND_URL = "https://open-ai-api-steel.vercel.app"; // Update this to your backend URL

    useEffect(() => {
        fetch(`${BACKEND_URL}/api/projects`)
            .then(response => response.json())
            .then(data => setProjects(data))
            .catch(error => console.error("There was an error!", error));
    }, []);

    const filteredProjects = filter ? projects.filter(project => project.category === filter) : projects;

    const handleFilterChange = (category) => () => {
        setFilter(category);
        console.log(category);
    };
    return (
        
        <div id='cont' className="container mx-auto px-4 py-8">
            <div className="flex flex-col md:flex-row items-center">
                {/* Text Section */}
                <div className="w-3/4 md:w-1/2 mx-20 mb-8 md:mb-0">
                    <h1 className="text-4xl font-bold mb-4">Hi, I am Sri.</h1>
                    <p className="text-xl mb-4">Technical Instructional Designer</p>
                    <div className="flex justify-center md:justify-start"> 
                        <ChatBubbleLeftIcon className="w-8 h-8 text-gray-700" />
                    </div>
                </div>
                
                {/* Hero Image */}
                <div className="w-full md:w-1/2 mx-auto flex justify-center">
                    <img src={myHeroImage} alt="Srivatsan Rangarajan" className="rounded-lg shadow-lg max-w-sm md:max-w-md" />
                </div>
            </div>

            <h2 className="text-2xl text-center font-bold mt-20">What I do</h2>

            {/* Buttons Section */}
            <div className="flex flex-wrap justify-center gap-8 mt-8">
                <button onClick={handleFilterChange('Learning Design')} 
                        className={`btn ${filter === 'Learning Design' ? 'bg-#010323 text-white' : 'bg-blue-500 text-black'}`}>Instructional Designer</button>
                <button onClick={handleFilterChange('Game Design')} 
                        className={`btn ${filter === 'Game Design' ? 'bg-blue-700 text-white' : 'bg-blue-500 text-black'}`}>Game Designer</button>
                <button onClick={handleFilterChange('UX Design')} 
                        className={`btn ${filter === 'UX Design' ? 'bg-blue-700 text-white' : 'bg-blue-500 text-black'}`}>UX Designer</button>
                <button onClick={handleFilterChange('Web Development')} 
                        className={`btn ${filter === 'Web Development' ? 'bg-blue-700 text-white' : 'bg-blue-500 text-black'}`}>App Developer</button>
                <button onClick={handleFilterChange('')} 
                        className={`btn ${filter === '' ? 'bg-blue-700 text-white' : 'bg-blue-500 text-black'}`}>Show All</button>
            </div>

            {/* Projects Section */}
            <div className="container mx-auto px-4 py-8 grid grid-cols-1 md:grid-cols-1 gap-4">
                {filteredProjects.map((project, index) => (
                    <ProjectCard key={index} {...project} />
                ))}
            </div>
        </div>
    );
};

export default HomePage;
