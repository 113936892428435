import React from 'react';
import './AboutMe.css';
import myLargeImage from '../assets/Sri.png'; 

const About = () => {
    return (
        <div class="about-me-container">
            <div class="icon-container">
                <div class="icon">Icon 1</div>
                <div class="icon">Icon 2</div>
                <div class="icon">Icon 3</div>
                <div class="icon">Icon 4</div>
                <div class="icon">Icon 5</div>
            </div>
            <img src={myLargeImage} alt="Srivatsan R" class="central-image"/>
        </div>
    );
};

export default About;
