import React, { useState, useEffect } from 'react';
import ProjectCard from './ProjectCard';

// const uxProjects = [
//     {
//         title: "UX Project 1",
//         description: "An innovative solution to improve e-commerce shopping experience.",
//         imageUrl: "path/to/image.jpg",
//         technologies: ["Figma", "Adobe XD"],
//         link: "http://example.com",
//         caseStudyLink: "http://example.com/case-study"
//     },
// ];

const UXDesigner = () => {
    const [projects, setProjects] = useState([]);

    useEffect(() => {
        fetch('http://localhost:5000/api/projects')
            .then(response => response.json())
            .then(data => setProjects(data))
            .catch(error => console.error("There was an error!", error));
    }, []);

    return (
        <div className="container mx-auto px-4 py-8 grid grid-cols-1 md:grid-cols-3 gap-4">
            {projects.map((project, index) => (
                <ProjectCard key={index} {...project} />
            ))}
        </div>
    );
};


export default UXDesigner;
